import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import utils from './mixins/utils.js';
import axios from "axios";
import VueAxios from "vue-axios";
import VueHead from 'vue-head'

require("@/assets/sass/app.scss");

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueHead);
Vue.mixin(utils);

new Vue({
  router,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
