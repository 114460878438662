export default {
  methods: {
      createSearchResult(text, limit=18){
          let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
          let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
          if(!token || !user_id){
            this.$router.push({name: 'Login'});
          }

          let self_router = this.$router;
          this.search_text = text;

          let search_box = document.getElementById("js-search-box");
          if(search_box != null && typeof search_box != 'undefined' && search_box.childElementCount){
            for (const item of search_box.children) {
              item.classList.remove('fadein');
            }
          }

          this.axios({
            method: 'get',
            headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
            url: process.env.VUE_APP_API_SERVER_URL + `/api/search-result.php?search_text=${text}&limit=${limit}`,
          })
          .then((response) => {
            if(!response.data.status){
              self_router.push({name: 'Login'}); // エラーの場合、ログイン画面に遷移。
              return;
            }
            if(response.data.result.length){
              this.search_result = response.data.result;
              let is_flip = ((document.cookie + ';').match('is_flip=([^¥S;]*)')||[])[1];
              if(is_flip == "0"){
                this.toggle_text = "ウラ";
              }else{
                this.toggle_text = "オモテ";
              }
              this.$nextTick(function () {
                  let search_box = document.getElementById("js-search-box");
                  if(typeof search_box != 'undefined' && search_box.childElementCount){
                    let cnt=0;
                    for (const item of search_box.children) {
                      setTimeout(function(){
                        let is_flip = ((document.cookie + ';').match('is_flip=([^¥S;]*)')||[])[1];
                        if(is_flip > 0){
                          item.classList.add('fadein','flip_hover');
                          document.getElementById('js-toggle').checked = true;
                        }else{
                          item.classList.add('fadein');
                          document.getElementById('js-toggle').checked = false;
                        }
                      },500 + cnt * 100);
                      cnt++;
                    }
                  }
              })
              return;
            }
            // 検索結果が見つかりませんページ。
          })
          .catch((e) => {
            console.log(e.response.data.error_code); // エラーコードを参照
          })
      },
      createUrl(url){
        if(url){
          return process.env.VUE_APP_API_SERVER_URL + url;
        }
        return process.env.VUE_APP_API_SERVER_URL + "/storage/icons/no_img.png";
      },
      createNoImgUrl(){
        return process.env.VUE_APP_API_SERVER_URL + "/storage/icons/no_img_kanakana.jpg";
      },
    }
 }