<template>
  <div id="app">
      <!-- <Header></Header> -->
      <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
  }),
  head: {
    link: [
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Noto+Sans+JP' },
      ],
  },
};
</script>
