<template>
  <div class="register">
    <div class="register_container">
      <div class="register_title">ようこそ！</div>
      <div class="register_description">
        <div class="register_description_text">利用規約・個人情報の取り扱いについて<br>を確認し同意の上、登録してください</div>
      </div>
      <form class="register_box" @submit="checkForm" @submit.prevent="registerSubmit" method="post">
        <!-- <input type="hidden" name="token" v-model="token"> -->
        <span class="register_box_text">ニックネーム</span>
        <input class="register_box_nicknameinput" type="text" v-model="nickname">
        <span class="register_box_text">メールアドレス</span>
        <input class="register_box_mailinput" type="text" v-model="mail">
        <span class="register_box_text">パスワード</span>
        <input class="register_box_passinput" type="password" v-model="password">
        <input class="register_box_mailsubmit" type="submit" value="メールアドレスで登録" :disabled="submitFlg">
      </form>
      <span class="register_text register_ng" v-show="ngFlg">登録に失敗しました</span>
      <form @submit="loginBack">
        <input class="register_back" type="submit" value="ログイン画面へ戻る">
      </form>
      <span class="register_text">登録処理に時間がかかりますので、しばらくそのままお待ちください</span>
      <span class="register_text">メールアドレスはログイン及び、通知先として利用及び保存されます</span>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    // token:'',
    nickname:'',
    mail:'',
    password:'',
    submitFlg: false,
    ngFlg: false,
  }),
  created: function() {
    // this.getCSRFToken();
  },
  mounted() {
  },
  methods: {
    checkForm(e) {
      this.ngFlg = false;
      if (!!this.nickname && !!this.mail && !!this.password) {
        this.submitFlg = true;
        return true;
      }
      e.preventDefault();
      alert("ニックネーム、メールアドレス、パスワードを全て入力してください");
      return false;
    },
    registerSubmit() {
      let params = new URLSearchParams();
      // params.append('token', this.token);
      params.append('nick_name', this.nickname);
      params.append('mail', this.mail);
      params.append('password', this.password);
 
      let self = this;
      let self_router = this.$router;

      this.axios.post(process.env.VUE_APP_API_SERVER_URL + '/api/register.php', params)
        .then(function(response){
          if(response.data.status){
            document.cookie = "user_id=" + response.data.result["user_id"];
            document.cookie = "token=" + response.data.result["token"];
            self_router.push({name: 'RegisterComplete', params: {message: "テスト"}});
          } else {
            // エラーコード処理
            if (response.data.error_code == '10001') {
              alert('既に登録されています\n研究員のSlackで登録できなーいと叫んでくれたら駆けつけます！');
            } else {
              alert('システム異常が発生しました。\n以下のエラーコードと一緒に研究員のSlackで叫んでくさださい！\nエラーコード：' + response.data.error_code);
            }
            self.ngFlg = true;
          }
        })
        .catch(function(e){
          self.ngFlg = true;
          console.log(e);
        })
        .finally(() => {
        });
    },
    loginBack() {
      this.$router.push({name: 'Login'});
    }
  },
};
</script>
