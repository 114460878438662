<template>
  <div>
    <!-- ヘッダー -->
    <Header></Header>
    <!-- メインコンテンツ -->
    <div class="home">
      <div class="home_top">
        <img class="home_top_img" src='../images/mv.png'>
      </div>
      <div class="home_search">
        <div class="home_search_bar">
          <p>研究員を探す</p>
        </div>
        <input type="checkbox" class="home_search_check" :checked="more_check">
        <div class="home_search_main">
          <div class="home_search_main_jobs">
            <p>職種別</p>
            <div class="home_search_main_jobs_list">
              <p class="home_search_main_jobs_list_text" v-for="job in jobs" :key="job" v-on:click="searchKeyword(job)">{{ job }}</p>
            </div>
          </div>
          <div class="home_search_main_works">
            <p>働き方別</p>
            <div class="home_search_main_works_list">
              <p class="home_search_main_works_list_text" v-for="work in works" :key="work" v-on:click="searchKeyword(work)">{{ work }}</p>
            </div>
          </div>
          <div class="home_search_main_areas">
            <p>居住地別</p>
            <div class="home_search_main_areas_list">
              <p class="home_search_main_areas_list_text" v-for="area in areas" :key="area" v-on:click="searchKeyword(area)">{{ area }}</p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <input type="button" class="home_search_more" id="more_open" value="全て見る" :hidden="!more_check" v-on:click="moreOpen()">
        <input type="button" class="home_search_more" id="more_close" value="隠す" :hidden="more_check" v-on:click="moreClose()">
        <div class="home_search_free_box">
          <p class="home_search_free_box_text">フリーワードで検索</p>
          <input class="home_search_free_box_input" type="text" v-model="b_search_text" @keyup.enter="search">
        </div>
      </div>
    </div>
    <hr class="hr100">
    <Party></Party>
    <hr class="hr80">
    <Information></Information>
    <!-- フッター -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import Party from '../components/Party.vue';
import Information from '../components/Information.vue';

export default {
  data: () => ({
    b_search_text: "",
    more_check: true,
    jobs: [],
    works: [],
    areas: [],
  }),
  components: {
    Header,
    Footer,
    Party,
    Information
  },
  created() {
  },
  mounted() {
  },
  beforeCreate() {
    // tokenkeyがなければログイン画面に戻す
    let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
    if (!token) {
      this.$router.push({name: 'Login'});
    }

    let self = this;
    this.axios({
      method: 'get',
      headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
      url: process.env.VUE_APP_API_SERVER_URL + '/api/master-data.php',
    })
    .then(function(response) {
      console.log(response);
      response.data.result.address_area.forEach(function(x) {
        self.areas.push(x.item_val);
      });
      response.data.result.job.forEach(function(x) {
        self.jobs.push(x.item_val);
      });
      response.data.result.work.forEach(function(x) {
        self.works.push(x.item_val);
      });
    })
    .catch(function(e) {
      console.log(e);
    })
  },
  methods: {
    search() {
      // 他画面から検索結果へ遷移して検索
      if (this.$route.path != '/search-result') {
        // 画面上部に戻る
        location.href = "#";
        this.$router.push({ path: 'search-result', query: { search_text: this.b_search_text }});
        return;
      }
      // 検索結果画面から検索
      this.$emit('createSearchResultForHeader', this.b_search_text);
    },
    searchKeyword(keyword) {
      // 画面上部に戻る
      location.href = "#";
      this.$router.push({ path: 'search-result', query: { search_text: keyword }});
      return;
    },
    moreOpen() {
      this.more_check = false;
    },
    moreClose() {
      this.more_check = true;
    },
  },
};
</script>