<template>
  <div>
    <Header></Header>
    <form>
      <div class="edit">
        <div class="edit_title">
          <p>PROFILE EDIT</p>
        </div>
        <div class="edit_box">
          <div class="edit_box_left">
            <table>
              <tr>
                <th>アイコン</th>
                <td colspan="2" class="icon">
                  <img v-if="imageData" :src="imageData" class="icon_img">
                  <img v-else :src="createUrl(link_img_icon_url)" class="icon_img">
                  <div class="icon_edit_mark">
                    <img :src="createUrl('/storage/icons/edit.png')" class="icon_edit_mark_img">
                  </div>
                  <label class="icon_edit">
                    <input @change="selectedFile" type="file" name="file" class="icon_edit_input" accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp,image/tiff,image/apng">
                  </label>
                  <button v-if="uploadFile" @click="upload" class="icon_upload">画像登録</button>
                </td>
              </tr>
              <tr>
                <th>名前<span>*</span><br>(ニックネーム優先)</th>
                <td colspan="2"><input type="text" v-model="user_name"></td>
              </tr>
              <tr>
                <th>ニックネーム</th>
                <td colspan="2"><input type="text" v-model="nick_name"></td>
              </tr>
              <tr>
                <th>キャッチコピー</th>
                <td colspan="2"><input type="text" v-model="catch_copy"></td>
              </tr>
              <tr>
                <th>メールアドレス<span class="red">*</span><br>(非公開項目)</th>
                <td colspan="2"><input type="text" v-model="mail_address"></td>
              </tr>
              <tr>
                <th>ランサーズID</th>
                <td colspan="2"><input type="text" v-model="lancers_id"></td>
              </tr>
              <tr>
                <th>ランサーズランク</th>
                <td colspan="2">
                  <select v-model="lancers_rank">
                    <option v-for="x in m_lancers_rank" :key="x.imte_id" :value="x.item_id">{{ x.item_val }}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>年齢<br>(非公開項目)</th>
                <td colspan="2"><input type="text" v-model="age"></td>
              </tr>
              <tr>
                <th>年代<span>*</span></th>
                <td class="col1">
                  <select v-model="age_range">
                    <option v-for="x in m_age_range" :key="x.imte_id" :value="x.item_id">{{ x.item_val }}</option>
                  </select>
                </td>
                <td class="col2">
                  <input type="radio" name="radio_age" id="age_on" value="1" v-model="ps_age_range">
                  <label class="radio_left" for="age_on">公開する</label>
                  <input type="radio" name="radio_age" id="age_off" value="0" v-model="ps_age_range">
                  <label class="radio_right" for="age_off">公開しない</label>
                </td>
              </tr>
              <tr>
                <th>性別<span>*</span></th>
                <td class="col1">
                  <select v-model="sex">
                    <option v-for="x in m_sex" :key="x.imte_id" :value="x.item_id">{{ x.item_val }}</option>
                  </select>
                </td>
                <td class="col2">
                  <input type="radio" name="radio_sex" id="sex_on" value="1" v-model="ps_sex">
                  <label class="radio_left" for="sex_on">公開する</label>
                  <input type="radio" name="radio_sex" id="sex_off" value="0" v-model="ps_sex">
                  <label class="radio_right" for="sex_off">公開しない</label>
                </td>
              </tr>
              <tr>
                <th>住所<br>(非公開項目)</th>
                <td colspan="2"><input type="text" v-model="address"></td>
              </tr>
              <tr>
                <th>居住地<span>*</span></th>
                <td class="col1">
                  <select v-model="address_area">
                    <option v-for="x in m_address_area" :key="x.imte_id" :value="x.item_id">{{ x.item_val }}</option>
                  </select>
                </td>
                <td class="col2">
                  <input type="radio" name="radio_area" id="area_on" value="1" v-model="ps_address_area">
                  <label class="radio_left" for="area_on">公開する</label>
                  <input type="radio" name="radio_area" id="area_off" value="0" v-model="ps_address_area">
                  <label class="radio_right" for="area_off">公開しない</label>
                </td>
              </tr>
              <tr>
                <th>プロフィール</th>
                <td colspan="2"><textarea v-model="profile"></textarea></td>
              </tr>
              <tr v-for="x in jobs" :key="x.index">
                <th v-if="x.index == 1">職種</th>
                <th v-else></th>
                <td class="col1">
                  <select v-model="x.job_id">
                    <option v-for="y in m_job" :key="y.imte_id" :value="y.item_id">{{ y.item_val }}</option>
                  </select>
                </td>
                <td class="col2_2">
                  <input type="button" class="add" value="追加" v-show="isFirstJob(x.index)" v-on:click="addJob(x.index)">
                  <input type="button" class="delete" value="削除" v-show="!isFirstJob(x.index)" v-on:click="delJob(x.index)">
                </td>
              </tr>
              <tr>
                <th>働き方</th>
                <td colspan="2">
                  <select v-model="work">
                    <option v-for="x in m_work" :key="x.imte_id" :value="x.item_id">{{ x.item_val }}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>働き方詳細</th>
                <td colspan="2"><textarea v-model="work_detail"></textarea></td>
              </tr>
              <tr>
                <th>Twitter</th>
                <td colspan="2"><input type="text" v-model="link_twitter" placeholder="アカウントのみ入力してください(例:@atalab)"></td>
              </tr>
              <tr>
                <th>Facebook</th>
                <td colspan="2"><input type="text" v-model="link_facebook" placeholder="マイページのURLのid=xxxxxのxxxxxの部分を入力してください"></td>
              </tr>
              <tr>
                <th>Instagram</th>
                <td colspan="2"><input type="text" v-model="link_instagram" placeholder="アカウントのみ入力してください"></td>
              </tr>
              <tr>
                <th>YouTube</th>
                <td colspan="2"><input type="text" v-model="link_youtube" placeholder="https://www.youtube.com/channel/以降を入力してください"></td>
              </tr>
              <tr>
                <th>linkedin</th>
                <td colspan="2"><input type="text" v-model="link_linkedin" placeholder="https://www.linkedin.com/in/以降を入力してください"></td>
              </tr>
              <tr>
                <th>note</th>
                <td colspan="2"><input type="text" v-model="link_note" placeholder="アカウントのみ入力してください"></td>
              </tr>
            </table>
          </div>
          <div class="edit_box_right">
            <div class="edit_box_right_row" v-for="theme in themes" :key="theme.id">
              <table>
                <tr>
                  <th>実験テーマ</th>
                  <td colspan="2"><input type="text" v-model="theme.title"></td>
                </tr>
                <tr>
                  <th>実験概要</th>
                  <td class="col2_1"><textarea v-model="theme.detail"></textarea></td>
                  <td class="col2_2"><input type="button" class="delete" value="削除" v-on:click="delResearch(theme.id)"></td>
                </tr>
              </table>
            </div>
          <div class="edit_box_right_add">
            <input type="button" class="edit_box_right_add_button" value="実験追加" v-on:click="addResearch()">
          </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <div class="edit_submit">
        <input type="button" class="edit_submit_button" value="更新する" v-on:click="edit()">
      </div>
    </form>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

export default {
  data: () => ({
    catch_copy: "",
    user_name: "",
    nick_name: "",
    mail_address: "",
    user_id: "",
    lancers_id: "",
    lancers_rank: "",
    age: "",
    age_range: "",
    ps_age_range: false,
    sex: "",
    ps_sex: false,
    address: "",
    address_area: "",
    ps_address_area: false,
    profile: "",
    jobs: [],
    work: "",
    work_detail: "",
    link_lancers: "",
    link_facebook: "",
    link_img_bg_url: "",
    link_img_icon_url: "",
    link_instagram: "",
    link_linkedin: "",
    link_note: "",
    link_twitter: "",
    link_youtube: "",
    proj_adobe: "",
    proj_hp: "",
    proj_udemy: "",
    proj_lac: "",
    proj_thinlab: "",
    uploadFile: null,
    themes: [],
    imageData:null,
    m_address_area: [],
    m_age_range: [],
    m_job: [],
    m_sex: [],
    m_lancers_rank: [],
    m_work: [],
  }),
  components: {
    Header,
    Footer,
  },
  beforeCreate() {
    let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
    let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
    if(!token || !user_id){
      this.$router.push({name: 'Login'});
    }

    let self = this;
    let self_router = this.$router;

    this.axios({
      method: 'get',
      headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
      url: process.env.VUE_APP_API_SERVER_URL + '/api/mypage.php?user_id=' + user_id,
    })
    .then(function(response){
      console.log(response);
      if (!response.data.status) {
        self_router.push({name: 'Login'}); // エラーの場合、ログイン画面に遷移。
      }
      let user_info = response.data.result.user_info;
      let job_info = response.data.result.job_info;
      let research_info = response.data.result.research_info;

      self.axios({
        method: 'get',
        headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
        url: process.env.VUE_APP_API_SERVER_URL + '/api/master-data.php',
      })
      .then(function(response){
        console.log(response);
        if (!response.data.status) {
          self_router.push({name: 'Login'}); // エラーの場合、ログイン画面に遷移。
        }
        self.m_address_area = response.data.result.address_area;
        self.m_age_range = response.data.result.age_range;
        self.m_job = response.data.result.job;
        self.m_sex = response.data.result.sex;
        self.m_lancers_rank = response.data.result.lancers_rank;
        self.m_work = response.data.result.work;
        self.catch_copy = user_info.catch_copy == 'null' ? '' : user_info.catch_copy;
        self.user_name = user_info.user_name;
        self.nick_name = user_info.nick_name;
        self.mail_address = user_info.mail_address;
        self.lancers_id = user_info.lancers_id;
        self.user_id = ('000000' + user_info.id).slice(-6);
        job_info.forEach(function(x) {
          self.jobs.push({
            index: Object.keys(self.jobs).length + 1,
            job_id: x.job_id,
          });
        }); 
        self.link_img_icon_url = user_info.link_img_icon_url;
        self.lancers_id = user_info.lancers_id;
        self.lancers_rank = user_info.lancers_lank_id;
        self.age = user_info.age == 0 ? null : user_info.age;
        self.age_range = user_info.age_range_id;
        self.ps_age_range = user_info.publish_settings.age_range ? "1" : "0";
        self.sex = user_info.sex_id;
        self.ps_sex = user_info.publish_settings.sex ? "1" : "0";
        self.address = user_info.address;
        self.address_area = user_info.address_area_id;
        self.ps_address_area = user_info.publish_settings.address_area ? "1" : "0";
        self.work = user_info.work_id;
        self.work_detail = user_info.work_detail;
        self.link_facebook = user_info.link_facebook == 'mull' ? '' : user_info.link_facebook;
        self.link_instagram = user_info.link_instagram == 'mull' ? '' : user_info.link_instagram;
        self.link_linkedin = user_info.link_linkedin == 'mull' ? '' : user_info.link_linkedin;
        self.link_note = user_info.link_note == 'mull' ? '' : user_info.link_note;
        self.link_twitter = user_info.link_twitter == 'mull' ? '' : user_info.link_twitter;
        self.link_youtube = user_info.link_youtube == 'mull' ? '' : user_info.link_youtube;
        self.proj_adobe = user_info.proj_adobe;
        self.proj_hp = user_info.proj_hp;
        self.proj_udemy = user_info.proj_udemy;
        self.proj_lac = user_info.proj_lac;
        self.proj_thinlab = user_info.proj_thinlab;
        research_info.forEach(function(x) {
        let theme = {
          id: Object.keys(self.themes).length + 1,
          title: x.title,
          detail: x.detail,
          purpose: x.purpose,
          verification: x.verification,
          overview: x.overview,
          etc: x.etc,
        };
        self.themes.push(theme);
      });
      if (!self.themes) {
        self.themes.push({
          id: 1,
          title: '',
          detail: '',
          purpose: '',
          verification: '',
          overview: '',
          etc: '',
        });
      }
    })
      .catch(function(e) {
        console.log(e);
      });
    })
    .catch(function(e){
      console.log(e);
      // console.log(e.response.data.error_code); // エラーコードを参照
    })
  },
  methods: {
    selectedFile(e) {
        e.preventDefault();
        let files = e.target.files;
        this.uploadFile = files[0];

        if(files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
              this.imageData = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    },
    upload(e) {
        e.preventDefault();
        let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
        let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
        if(!token || !user_id){
          this.$router.push({name: 'Login'});
        }

        let formData = new FormData();
        formData.append('img_type', "icon");
        formData.append('upload_image', this.uploadFile);
        formData.append('user_id', user_id);
        formData.append('_method', 'PUT');

        this.axios({
            method: "post",
            url: process.env.VUE_APP_API_SERVER_URL + '/api/mypage-upload-image.php',
            data: formData,
            headers: {Authorization: `Bearer: ${token}`, 'content-type': 'multipart/form-data'},
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
        })
        .then(function(response) {
            console.log(response);
        })
        .catch(function(e) {
            console.log(e);
        })
    },
    edit() {
        let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
        let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
        if (!token || !user_id) {
          this.$router.push({name: 'Login'});
        }

        let research_title = "";
        let research_detail = "";
        let research_purpose = "";
        let research_verification = "";
        let research_overview = "";
        let research_etc = "";
        this.themes.forEach(function(x) {
          if (x.title) {
            if (research_title) {
              research_title += ",";
              research_detail += ",";
              research_purpose += ",";
              research_verification += ",";
              research_overview += ",";
              research_etc += ",";
            }
            research_title += x.title;
            research_detail += x.detail;
            research_purpose += x.purpose;
            research_verification += x.verification;
            research_overview += x.overview;
            research_etc += x.etc;
          }
        });

        let jobList = "";
        this.jobs.forEach(function(x) {
          if (x.job_id) {
            if (jobList) {
              jobList += ",";
            }
            jobList += x.job_id;
          }
        });

        let formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('user_name', this.user_name);
        formData.append('mail_address', this.mail_address);
        formData.append('nick_name', this.nick_name);
        formData.append('catch_copy', this.catch_copy);
        formData.append('lancers_id', this.lancers_id);
        formData.append('lancers_rank', this.lancers_rank);
        formData.append('age', this.age);
        formData.append('age_range', this.age_range);
        formData.append('sex', this.sex);
        formData.append('address', this.address);
        formData.append('address_area', this.address_area);
        formData.append('profile', this.profile);
        formData.append('job_ids', jobList);
        formData.append('work', this.work);
        formData.append('work_detail', this.work_detail);
        formData.append('proj_adobe', this.proj_adobe);
        formData.append('proj_hp', this.proj_hp);
        formData.append('proj_udemy', this.proj_udemy);
        formData.append('proj_lac', this.proj_lac);
        formData.append('proj_thinlab', this.proj_thinlab);
        formData.append('link_twitter', this.link_twitter);
        formData.append('link_facebook', this.link_facebook);
        formData.append('link_instagram', this.link_instagram);
        formData.append('link_youtube', this.link_youtube);
        formData.append('link_linkedin', this.link_linkedin);
        formData.append('link_note', this.link_note);
        formData.append('research_titles', research_title);
        formData.append('research_details', research_detail);
        formData.append('research_purposes', research_purpose);
        formData.append('research_verifications', research_verification);
        formData.append('research_overviews', research_overview);
        formData.append('research_etcs', research_etc);
        formData.append('ps_mail_address', false);
        formData.append('ps_user_name', true);
        formData.append('ps_catch_copy', true);
        formData.append('ps_lancers_id', true);
        formData.append('ps_lancers_rank', true);
        formData.append('ps_level', false);
        formData.append('ps_age', false);
        formData.append('ps_age_range', this.ps_age_range == "1" ? true : false);
        formData.append('ps_sex', this.ps_sex == "1" ? true : false);
        formData.append('ps_address', false);
        formData.append('ps_address_area', this.ps_address_area == "1" ? true : false);
        formData.append('ps_profile', true);
        formData.append('ps_work', true);
        formData.append('ps_work_detail', true);
        formData.append('ps_proj_adobe', false);
        formData.append('ps_proj_hp', false);
        formData.append('ps_proj_udemy', false);
        formData.append('ps_proj_lac', false);
        formData.append('ps_proj_thinlab', false);
        formData.append('ps_link_twitter', true);
        formData.append('ps_link_facebook', true);
        formData.append('ps_link_instagram', true);
        formData.append('ps_link_youtube', true);
        formData.append('ps_link_linkedin', true);
        formData.append('ps_link_note', true);
        formData.append('_method', 'PUT');

        this.axios({
            method: "post",
            url: process.env.VUE_APP_API_SERVER_URL + '/api/mypage-edit.php',
            data: formData,
            headers: {Authorization: `Bearer: ${token}`, 'content-type': 'application/json'},
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
        })
        .then(function(response) {
            console.log(response);
            alert('登録が完了しました');
        })
        .catch(function(e) {
            console.log(e);
        })
    },
    isFirstJob(index) {
      return Object.keys(this.jobs).length == index;
    },
    addJob() {
      let job = {
        index: Object.keys(this.jobs).length + 1,
        job_id: "",
      };
      this.jobs.push(job);
    },
    delJob(index) {
      this.jobs = this.jobs.filter(function(x) {
        return x.index != index;
      })
      .map(function(x) {
        if (x.index > index) {
          x.index--;
        }
        return x;
      });
    },
    addResearch() {
      this.themes.push({
        id: Object.keys(this.themes).length + 1,
        title: '',
        detail: '',
        purpose: '',
        verification: '',
        overview: '',
        etc: '',
      });
    },
    delResearch(id) {
      this.themes = this.themes.filter(function(x) {
        return x.id != id;
      })
      .map(function(x) {
        if (x.id > id) {
          x.id--;
        }
        return x;
      });
    },
  }
};
</script>