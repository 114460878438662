<template>
  <div>
    <Header ref="headerForSearchResult" @createSearchResultForHeader="createSearchResultForHeader"></Header>
    <div class="search">
      <h2 class="search_title">Researcher</h2>
      <section>

        <div class="search_box" id="js-search-box">
          <aside class="search_box_setting">
            <span>{{toggle_text}}</span>
            <div class="toggle-switch">
              <input id="js-toggle" class="toggle-input" type='checkbox' @click="changeCard" />
              <label for="js-toggle" class="toggle-label"/>
            </div>
          </aside>
          <div v-for="(item, index) in search_result" :key="item.id"
            :class="['card', 'flip']"
            >
            <div class="front">
                <a :href="'/search-result-detail?detail_id=' + item.id" class="card_link">
                  <div :class="['card_item', setBgClass(index)]">
                    <div class="card_item_img">
                      <img :src="createNoImgUrl()" class="card_item_icon">
                    </div>
                  </div>
                </a>
            </div>
            <div class="back">
              <a :href="'/search-result-detail?detail_id=' + item.id" class="card_link">
                <div :class="['card_item', setBgClass(index)]">
                  <div class="card_item_img">
                    <img :src="createUrl(item.link_img_icon_url)" class="card_item_icon">
                  </div>
                  <div class="card_item_catchcopy">
                    <p class="card_item_catchcopy_text">{{ item.catch_copy }}</p>
                  </div>
                </div>
              </a>
              <p class="card_name">{{item.nick_name}}</p>
            </div>

          </div>
          <div class="search_box_btn" v-show="is_show_search_box_btn">
            <button class="search_box_btn_link" @click="displaySearchResult()">
              <span>全て表示</span>
            </button>
          </div>
        </div>
      </section>
    </div>
    <div class="clear"></div>
    <hr class="hr100">
    <Party></Party>
    <hr class="hr80">
    <Information></Information>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import Party from '../components/Party.vue';
import Information from '../components/Information.vue';

export default {
  components: {
    Header,
    Footer,
    Party,
    Information,
  },
  data: () => ({
    search_result:[],
    toggle_text:"",
    search_text:"",
    is_show_search_box_btn:true
  }),
  created() {
    let text = "";
    if(this.$route.query.search_text) {
      text = this.$route.query.search_text;
    }

    this.search_text = text;
    this.createSearchResult(this.search_text);
  },
  mounted() {
    this.$refs.headerForSearchResult.setSearchText(this.search_text);
  },
  methods: {
    createSearchResultForHeader(text){
      this.is_show_search_box_btn = true;
      this.createSearchResult(text);
    },
    setBgClass(index){
      if(index % 2 == 0){
        return  "card_item_bgb";
      }
      return "card_item_bgy";
    },
    changeCard(){
      let search_box = document.getElementById("js-search-box");
      if(search_box != null && typeof search_box != 'undefined' && search_box.childElementCount){
        for (const item of search_box.children) {
          if(item.classList.contains('flip_hover') == true){
            item.classList.remove('flip_hover');
            this.toggle_text = "ウラ";
            document.cookie = "is_flip=0";
          }else{
            item.classList.add('flip_hover');
            this.toggle_text = "オモテ";
            document.cookie = "is_flip=1";
          }
        }
      }
    },
    displaySearchResult(){
      this.is_show_search_box_btn = false;
      this.createSearchResult(this.search_text, 0);
    }
  }
};
</script>