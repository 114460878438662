<template>
  <div class="admin">
    <Header ref="headerForSearchResult" @createSearchResultForHeader="createSearchResultForHeader"></Header>
    <div>
      <button @click="getCsv">CSVダウンロード</button>
    </div>
    <div :class="['admin_overlay', admin_overlay_open]"></div>
  </div>
</template>

<script>
import Header from '../components/Header.vue';

export default {
  components: {
    Header
  },
  data: () => ({
    admin_overlay_open: ""
  }),
  mounted: async function() {
    let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
    let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
    if(!token || !user_id){
      this.$router.push({name: 'Login'});
    }

    let self_router = this.$router;

    // 管理者権限チェック
    let is_admin = await this.axios({
                      method: 'get',
                      headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
                      url: process.env.VUE_APP_API_SERVER_URL + '/api/admin-check.php?user_id=' + user_id,
                    })
                    .then(function(response){
                      if(!response.data.status){
                        self_router.push({name: 'Login'});
                      }
                      return true;
                    })
                    .catch(function(e){
                      console.log(e);
                    })

    if(is_admin){
      this.admin_overlay_open = "admin_overlay_hidden";
    }
  },
  methods: {
    createSearchResultForHeader(text){
      this.createSearchResult(text);
    },
    getCsv(){
      let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
      let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
      if(!token || !user_id){
        this.$router.push({name: 'Login'});
      }

      // 管理者権限チェック
      this.axios({
        method: 'get',
        headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
        url: process.env.VUE_APP_API_SERVER_URL + '/api/admin-download-csv.php?user_id=' + user_id,
        responseType: 'blob'
      })
      .then(function(response){
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'user_list.csv');
        document.body.appendChild(link);
        link.click();
        link.revokeObjectURL();
      })
      .catch(function(e){
        console.log(e);
      })
    }
  },
};
</script>
