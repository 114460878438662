<template>
  <div>
    <Header></Header>
    <div class="mypage">
      <div class="mypage_title">
        <p>My PAGE</p>
      </div>
      <div class="mypage_row1">
        <div class="mypage_row1_col1">
          <div class="mypage_row1_col1_card">
            <div class="mypage_row1_col1_card_in">
              <div class="mypage_row1_col1_card_in_logo">
                <img class="mypage_row1_col1_card_in_logo_img" src="../images/logo-circle.png">
              </div>
              <div class="mypage_row1_col1_card_in_catchcopy">
                <p v-if="!!catch_copy">{{ catch_copy }}</p>
                <p v-else>新しい働き方ＬＡＢ 研究員(１期生)</p>
              </div>
              <div class="mypage_row1_col1_card_in_picture">
                <img v-if="!!link_img_icon_url" class="mypage_row1_col1_card_in_picture_img" :src="createUrl(link_img_icon_url)">
                <img v-else class="mypage_row1_col1_card_in_picture_img" src="../images/icon.png">
              </div>
              <div class="mypage_row1_col1_card_in_name">
                <p v-if="!!nick_name">{{ nick_name }}</p>
                <p v-else>{{ user_name }}</p>
              </div>
              <div class="mypage_row1_col1_card_in_jobs">
                <p v-if="jobs">{{ jobs }}</p>
                <p v-else>研究員</p>
              </div>
              <div class="mypage_row1_col1_card_in_memberid">
                <p>ID:{{ user_id }}</p>
              </div>
              <div class="mypage_row1_col1_card_in_code">
                <div class="mypage_row1_col1_card_in_code_bar">
                  <img class="mypage_row1_col1_card_in_code_bar_img" src="../images/barcode.png">
                </div>
                <div class="mypage_row1_col1_card_in_code_qr">
                  <img class="mypage_row1_col1_card_in_code_qr_img" src="../images/qrcode.png">
                </div>
                <div class="clear"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="mypage_row1_col2">
          <div class="mypage_row1_col2_profile">
            <div class="mypage_row1_col2_profile_title">
              <p>プロフィール</p>
            </div>
            <div class="mypage_row1_col2_profile_detail">
              <table>
                <tr>
                  <th>ランサー名</th>
                  <td colspan="5">{{ lancers_name }}</td>
                </tr>
                <tr>
                  <th>年代</th>
                  <td>{{ age_range }}</td>
                  <th>性別</th>
                  <td>{{ sex }}</td>
                  <th>居住地</th>
                  <td>{{ address_area }}</td>
                </tr>
                <tr>
                  <th>働き方</th>
                  <td colspan="5" v-if="!!work">{{ work }}</td>
                  <td colspan="5" v-else>研究員</td>
                </tr>
                <tr>
                  <th class="mypage_row1_col2_profile_detail_work">仕事内容</th>
                  <td colspan="5" class="mypage_row1_col2_profile_detail_work">{{ work_detail }}</td>
                </tr>
              </table>
              <div class="mypage_row1_col2_profile_detail_theme">
                <img class="mypage_row1_col2_profile_detail_theme_img" src="../images/blackboard.png">
                <div class="mypage_row1_col2_profile_detail_theme_text">{{ research_title }}</div>
              </div>
              <div class="mypage_row1_col2_profile_detail_alltheme">
                <form @submit.prevent="themeList">
                  <input class="mypage_row1_col2_profile_detail_alltheme_button" type="submit" value=">>> 全ての実験">
                </form>
              </div>
            </div>
          </div>
          <div class="mypage_row1_col2_sns">
            <div class="mypage_row1_col2_sns_title">
              <p>利用中のSNS</p>
            </div>
            <div class="mypage_row1_col2_sns_box">
              <div class="mypage_row1_col2_sns_box_icon" v-if="!!link_lancers">
                <a :href="link_lancers" target="_blank">
                  <img class="mypage_row1_col2_sns_box_icon_img" src="../images/lancers.png">
                </a>
              </div>
              <div class="mypage_row1_col2_sns_box_icon">
                <a href="https://app.slack.com/client/T01LTTW72HZ/" target="_blank">
                  <img class="mypage_row1_col2_sns_box_icon_img" src="../images/slack.png">
                </a>
              </div>
              <div class="mypage_row1_col2_sns_box_icon" v-if="!!link_twitter">
                <a :href="link_twitter" target="_blank">
                  <img class="mypage_row1_col2_sns_box_icon_img" src="../images/twitter.png">
                </a>
              </div>
              <div class="mypage_row1_col2_sns_box_icon" v-if="!!link_note">
                <a :href="link_note" target="_blank">
                  <img class="mypage_row1_col2_sns_box_icon_img" src="../images/note.png">
                </a>
              </div>
              <div class="mypage_row1_col2_sns_box_icon" v-if="!!link_facebook">
                <a :href="link_facebook" target="_blank">
                  <img class="mypage_row1_col2_sns_box_icon_img" src="../images/facebook.png">
                </a>
              </div>
              <div class="mypage_row1_col2_sns_box_icon" v-if="!!link_instagram">
                <a :href="link_instagram" target="_blank">
                  <img class="mypage_row1_col2_sns_box_icon_img" src="../images/instagram.png">
                </a>
              </div>
              <div class="mypage_row1_col2_sns_box_icon" v-if="!!link_youtube">
                <a :href="link_youtube" target="_blank">
                  <img class="mypage_row1_col2_sns_box_icon_img" src="../images/youtube.png">
                </a>
              </div>
              <div class="mypage_row1_col2_sns_box_icon" v-if="!!link_linkedin">
                <a :href="link_linkedin" target="_blank">
                  <img class="mypage_row1_col2_sns_box_icon_img" src="../images/linkedin.png">
                </a>
              </div>
            </div>
          </div>
          <div class="mypage_row1_col2_edit">
            <form @submit.prevent="edit">
              <input class="mypage_row1_col2_edit_button" type="submit" value="プロフィールを編集する">
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <hr class="hr100">
    <Party></Party>
    <hr class="hr80">
    <Information></Information>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import Party from '../components/Party.vue';
import Information from '../components/Information.vue';

export default {
  data: () => ({
    catch_copy: "",
    user_name: "",
    nick_name: "",
    jobs: "",
    user_id: "",
    lancers_name: "",
    age_range: "",
    sex: "",
    address_area: "",
    work: "",
    work_detail: "",
    research_title: "",
    link_lancers: "",
    link_facebook: "",
    link_img_bg_url: "",
    link_img_icon_url: "",
    link_instagram: "",
    link_linkedin: "",
    link_note: "",
    link_twitter: "",
    link_youtube: "",
  }),
  components: {
    Header,
    Footer,
    Party,
    Information,
  },
  beforeCreate() {
    let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
    let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
    if(!token || !user_id){
      this.$router.push({name: 'Login'});
    }

    let self = this;
    let self_router = this.$router;

    this.axios({
      method: 'get',
      headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
      url: process.env.VUE_APP_API_SERVER_URL + '/api/mypage.php?user_id=' + user_id,
    })
    .then(function(response) {
      console.log(response);
      if (!response.data.status) {
        self_router.push({name: 'Login'}); // エラーの場合、ログイン画面に遷移。
      }
      let user_info = response.data.result.user_info;
      let job_info = response.data.result.job_info;
      let research_info = response.data.result.research_info;
      self.catch_copy = user_info.catch_copy;
      self.link_img_icon_url = user_info.link_img_icon_url;
      self.user_name = user_info.user_name;
      self.nick_name = user_info.nick_name;
      self.user_id = ('000000' + user_info.id).slice(-6);
      job_info.forEach(function(x) {
        if (self.jobs) {
          self.jobs += "/";
        }
        self.jobs += x.item_val;
      }); 
      self.lancers_name = user_info.lancers_id;
      if (user_info.lancers_rank) {
        self.lancers_name += '(' + user_info.lancers_rank + ')';
      }
      if (user_info.age_range) {
        self.age_range = user_info.age_range;
      } else {
        self.age_range = '非公開';  
      }
      if (user_info.sex_id == 1 || user_info.sex_id == 2) {
        self.sex = user_info.sex;
      } else {
        self.sex = '非公開';  
      }
      if (user_info.address_area) {
        self.address_area = user_info.address_area;
      } else {
        self.address_area = '非公開';  
      }
      self.work = user_info.work;
      self.work_detail = user_info.work_detail;
      if (research_info[0]) {
        if (research_info[0].title.length <= 64) {
          self.research_title = research_info[0].title;
        } else {
          self.research_title = research_info[0].title.substring(0, 64);
        }
      }
      if (user_info.lancers_id) {
        self.link_lancers = 'https://www.lancers.jp/profile/' + user_info.lancers_id;
      }
      if (user_info.link_facebook) {
        self.link_facebook = "https://www.facebook.com/profile.php?id=" + user_info.link_facebook;
      }
      if (user_info.link_instagram) {
        self.link_instagram = "https://www.instagram.com/" + user_info.link_instagram + "/?hl=ja";
      }
      if (user_info.link_linkedin) {
        self.link_linkedin = "https://www.linkedin.com/in/" + user_info.link_linkedin;
      }
      if (user_info.link_note) {
        self.link_note = "https://note.com/" + user_info.link_note;
      }
      if (user_info.link_twitter) {
        self.link_twitter = "https://twitter.com/" + user_info.link_twitter.replace("@", "");
      }
      if (user_info.link_youtube) {
        self.link_youtube = "https://www.youtube.com/channel/" + user_info.link_youtube;
      }
    })
    .catch(function(e) {
      console.log(e);
      // console.log(e.response.data.error_code); // エラーコードを参照
    })
  },
  methods: {
    themeList() {
      // 画面上部に戻る
      location.href = "#";
       // 編集画面に遷移
      let self_router = this.$router;
      self_router.push({path: 'theme-list', query: { target_id: ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1]}});
    },
    edit() {
      // 画面上部に戻る
      location.href = "#";
       // 編集画面に遷移
      let self_router = this.$router;
      self_router.push({name: 'ProfileEdit'});
    },
  }
};
</script>