<template>
  <div class="header">
    <div class="header_main">
      <div class="header_main_logo" v-on:click="home()">
        <img class="header_main_logo_img" src='../images/logo.png'>
      </div>
      <div class="header_main_search">
        <input class="header_main_search_input" type="text" v-model="h_search_text" @keyup.enter="search">
      </div>
      <!-- <div class="header_main_icon">
        <div class="header_main_icon_message">
          <img class="header_main_icon_message_img" src='../images/mail.png'>
        </div>
        <div class="header_main_icon_notice">
          <img class="header_main_icon_notice_img" src='../images/bell.png'>
        </div>
        <div class="header_main_icon_myprofile">
          <img class="header_main_icon_myprofile_img" src='../images/icon.png'>
        </div>
        <div class="header_main_icon_hamburger">
          <img class="header_main_icon_hamburger_img" src='../images/hamburger.png'>
        </div>
      </div> -->
    </div>
    <div class="clear"></div>
    <div class="header_menu">
      <div class="header_menu_home">
        <span @click="home">Home</span>
      </div>
      <div class="header_menu_mypage">
        <span @click="mypage">My PAGE</span>
      </div>
      <div class="header_menu_researcher">
        <span @click="researcher">Resercher</span>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    h_search_text: '',
  }),
  methods: {
    search() {
      // 他画面から検索結果へ遷移して検索
      if (this.$route.path != '/search-result'){
        this.$router.push({ path: 'search-result', query: { search_text: this.h_search_text }});
        return;
      }
      // 検索結果画面から検索
      this.$emit('createSearchResultForHeader', this.h_search_text);
    },
    setSearchText(text){
      this.h_search_text = text;
    },
    home() {
      let self_router = this.$router;
      self_router.push({name: 'Home'});
    },
    mypage() {
      let self_router = this.$router;
      self_router.push({name: 'Mypage'});
    },
    researcher() {
      let self_router = this.$router;
      self_router.push({ path: 'search-result', query: { search_text: "" }});
    }
  }
};
</script>