<template>
  <div class="register">
    <div class="register_container">
      <h1>登録完了</h1>
      ユーザーの登録が終了しました。<br>
      ログイン画面からログインしてください。<br><br>
      <form class="register_container_box" @submit="loginBack">
        <input class="register_container_box_loginback" type="submit" value="ログイン画面へ戻る">
      </form>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
  }),
  beforeCreate() {
    // tokenkeyがなければ新規登録画面に戻す
    let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
    if(!token){
      this.$router.push({name: 'Register'});
    }
  },
  mounted() {
  },
  methods: {
    loginBack() {
      let self_router = this.$router;
      self_router.push({name: 'Login'}); // ログイン画面に遷移
    }
  },
};
</script>
