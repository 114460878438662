<template>
  <div class="login">
    <div class="login_container">
      <div class="login_title">見える化PJにログイン</div>
      <form class="login_box" @submit="checkForm" @submit.prevent="login" method="post">
        <span class="login_box_text">ログインメールアドレス</span>
        <input class="login_box_mailinput" type="text" v-model="mail">
        <span class="login_box_text">パスワード</span>
        <input class="login_box_passinput" type="password" v-model="password">
        <input class="login_box_submit" type="submit" value="ログイン">
        <p class="login_box_or">または</p>
      </form>
      <form class="login_box" @submit.prevent="register">
        <input class="login_box_signin" type="submit" value="新規登録はこちら（無料）">
      </form>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    mail:'',
    password:''
  }),
  created: function() {
  },
  mounted() {
  },
  methods: {
    checkForm(e) {
      if (this.mail && this.password) {
        return true;
      }
      e.preventDefault();
      return false;
    },
    login() {
      let params = new URLSearchParams();
      params.append('mail', this.mail)
      params.append('password', this.password)
      
      let self_router = this.$router;

      this.axios.post(process.env.VUE_APP_API_SERVER_URL + '/api/login.php', params)
        .then(function(response){
          if (response.data.status) {
            // トークンをcookieに保存
            document.cookie = "user_id=" + response.data.result["user_id"];
            document.cookie = "token=" + response.data.result["token"];

            // トップページ or マイページへ遷移
            self_router.push({name: 'Mypage'})
          } else {
            alert('メールアドレス、もしくはパスワードが正しくありません');
          }
        })
        .catch(function(e){
            console.log(e);
        })
    },
    edit_pswd() {
      let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
      let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
      if(!token || !user_id){
        this.$router.push({name: 'Login'});
      }

      let formData = new FormData();
      formData.append('new_password', this.password);
      formData.append('user_id', user_id);

      this.axios({
        method: 'post',
        headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
        url: process.env.VUE_APP_API_SERVER_URL + '/api/password-edit.php',
        data: formData,
      })
      .then(function(response){
        if(response.data.status){
          console.log(response.data);
        }
        console.log(response.data);
      })
      .catch(function(e){
          console.log(e);
      })
    },
    logout() {
      let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
      if(!token){
        this.$router.push({name: 'Login'});
      }

      let self_router = this.$router;

      this.axios({
        method: 'post',
        headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
        url: process.env.VUE_APP_API_SERVER_URL + '/api/logout.php',
      })
      .then(function(){
          self_router.push({name: 'Login'}); // ログイン画面に遷移
      })
      .catch(function(){
          self_router.push({name: 'Login'}); // ログイン画面に遷移
        }
      )
    },
    register() {
      let self_router = this.$router;
      self_router.push({name: 'Register'}); // 新規登録画面に遷移
    }
  },
};
</script>
