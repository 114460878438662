<template>
  <div class="party">
    <div class="party_contents">
      <div class="party_contents_party">
        <img class="party_contents_party_img" src="../images/nakama.png">
      </div>
      <div class="party_contents_play">
        <img class="party_contents_play_img" src="../images/asobi.png">
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
};
</script>