import Vue from "vue";
import VueRouter from "vue-router";
// import Top from "../views/Top.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import RegisterComplete from "../views/RegisterComplete.vue";
import Mypage from "../views/Mypage.vue";
import ThemeList from "../views/ThemeList.vue";
import ProfileEdit from "../views/ProfileEdit.vue";
import SearchResult from "../views/SearchResult.vue";
import SearchResultDetail from "../views/SearchResultDetail.vue";
import Admin from "../views/Admin.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Top",
  //   component: Top,
  // },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register-complete",
    name: "RegisterComplete",
    component: RegisterComplete,
  },
  {
    path: "/mypage",
    name: "Mypage",
    component: Mypage,
  },
  {
    path: "/theme-list",
    name: "ThemeList",
    component: ThemeList,
  },
  {
    path: "/profile-edit",
    name: "ProfileEdit",
    component: ProfileEdit,
  },
  {
    path: "/search-result",
    name: "SearchResult",
    component: SearchResult,
  },
  {
    path: "/search-result-detail",
    name: "SearchResultDetail",
    component: SearchResultDetail,
  },
  {
    path: "/mgt-admin",
    name: "Admin",
    component: Admin,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
