<template>
  <div class="information">
    <div class="information_info">
      <div class="information_info_title">
        <p>新しい働き方LAB</p>
      </div>
      <div class="information_info_movies">
        <div class="information_info_movies_title">
          <p>学ぶ
          </p>
        </div>
        <div class="information_info_movies_list">
          <div class="information_info_movies_list_thumbnail">
            <a href="https://schoo.jp/lancers" target="_blank">
              <img class="information_info_movies_list_thumbnail_img" src="../images/learn_schoo.jpg">
            </a>
          </div>
          <div class="information_info_movies_list_explanation">
            <p>【有料】schooで学ぼう<br>プログラミング・ライティング etc...</p>
          </div>
        </div>
        <div class="information_info_movies_list">
          <div class="information_info_movies_list_thumbnail">
            <a href="https://techacademy.jp/first-programming-bootcamp?utm_source=lancers&utm_medium=referral&utm_campaign=freelancebasics" target="_blank">
              <img class="information_info_movies_list_thumbnail_img" src="../images/learn_techacademy.jpg">
            </a>
          </div>
          <div class="information_info_movies_list_explanation">
            <p>【有料】TechAcademyで学ぼう<br>プログラミング</p>
          </div>
        </div>
        <div class="information_info_movies_list">
          <div class="information_info_movies_list_thumbnail">
            <a href="https://www.tanomana.com/lpo/informationwork/startpack/lancers/" target="_blank">
              <img class="information_info_movies_list_thumbnail_img" src="../images/learn_humanacademy.png">
            </a>
          </div>
          <div class="information_info_movies_list_explanation">
            <p>【有料】ヒューマンアカデミーで学ぼう<br>Webデザイン・動画編集 etc...</p>
          </div>
        </div>
        <div class="information_info_movies_list">
          <div class="information_info_movies_list_thumbnail">
            <a href="https://www.youtube.com/playlist?list=PLF_lcvNhVWn9g1xp1sxycPkFlRRcRrq_Q" target="_blank">
              <img class="information_info_movies_list_thumbnail_img" src="../images/learn_adobe.jpg">
            </a>
          </div>
          <div class="information_info_movies_list_explanation">
            <p>【無料】Adobeを学ぼう<br>Premire Proで動画編集にチャレンジ！</p>
          </div>
        </div>
        <div class="information_info_movies_list">
          <div class="information_info_movies_list_thumbnail">
            <a href="https://menta.work/about" target="_blank">
              <img class="information_info_movies_list_thumbnail_img" src="../images/menta.jpg">
            </a>
          </div>
          <div class="information_info_movies_list_explanation">
            <p>【登録無料】MENTAで教わろう<br>オンラインメンターを探そう！</p>
          </div>
        </div>
        <div class="clear"></div>
        <div></div>
      </div>
      <br>
      <div class="information_info_events">
        <div class="information_info_events_title">
          <p>イベント</p>
        </div>
        <div class="information_info_events_list">
          <div class="information_info_events_list_thumbnail">
            <a href="https://www.lancers.jp/lab/detail/384" target="_blank">
              <img class="information_info_events_list_thumbnail_img" src="../images/event_20201023.jpg">
            </a>
          </div>
          <div class="information_info_events_list_explanation">
            <p>はじめてのクリエイティブを応援！<br>アドビフォトプラン初月80%OFF</p>
          </div>
        </div>
        <div class="information_info_events_list">
          <div class="information_info_events_list_thumbnail">
            <a href="https://www.lancers.jp/lab/detail/446" target="_blank">
              <img class="information_info_events_list_thumbnail_img" src="../images/event_20210701.jpg">
            </a>
          </div>
          <div class="information_info_events_list_explanation">
            <p>ランサーズ翻訳ブートキャンプ<br>技能編（第１期生）</p>
          </div>
        </div>
        <div class="information_info_events_list">
          <div class="information_info_events_list_thumbnail">
            <a href="https://www.youtube.com/watch?v=g6i5Zx7n_Qw&t=4s" target="_blank">
              <img class="information_info_events_list_thumbnail_img" src="../images/event_20210418.png">
            </a>
          </div>
          <div class="information_info_events_list_explanation">
            <p>【アーカイブ】LABイベント<br>新しい働き方フェス 2021</p>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>