<template>
  <div>
    <Header></Header>
    <div class="theme">
      <div class="theme_title">
        <p>THEME LIST</p>
      </div>
      <div class="theme_box">
        <div class="theme_box_div" v-for="theme in themes" :key="theme.id">
          <img class="theme_box_div_img" src="../images/blackboard.png">
          <div class="theme_box_div_text">【実験テーマ】<br>{{ theme.title }}<br><br>【実験概要】<br>{{ theme.detail ? theme.detail : "（未記入）" }}</div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <hr class="hr100">
    <Party></Party>
    <hr class="hr80">
    <Information></Information>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import Party from '../components/Party.vue';
import Information from '../components/Information.vue';

export default {
  data: () => ({
    themes: [],
  }),
  components: {
    Header,
    Footer,
    Party,
    Information,
  },
  beforeCreate() {
    let user_id = ((document.cookie + ';').match('user_id=([^¥S;]*)')||[])[1];
    let token = ((document.cookie + ';').match('token=([^¥S;]*)')||[])[1];
    if(!token || !user_id){
      this.$router.push({name: 'Login'});
    }

    let target_id = "";
    if (this.$route.query.target_id) {
      target_id = this.$route.query.target_id;
    }

    let self = this;
    let self_router = this.$router;

    this.axios({
      method: 'get',
      headers: {Authorization: `Bearer: ${token}`, 'Content-Type': 'application/json'},
      url: process.env.VUE_APP_API_SERVER_URL + '/api/search-result-detail.php?detail_id=' + target_id,
    })
    .then(function(response){
      console.log(response);
      if (!response.data.status) {
        self_router.push({name: 'Login'}); // エラーの場合、ログイン画面に遷移。
      }
      let research_info = response.data.result.research_info;
      research_info.forEach(function(x) {
        let theme = {
          id: Object.keys(self.themes).length + 1,
          title: x.title.length <= 60 ? x.title : x.title.substring(0, 58) + "...",
          detail: x.detail.length <= 210 ? x.detail : x.detail.substring(0, 208) + "...",
        };
        self.themes.push(theme);
      });
    })
    .catch(function(e){
      console.log(e);
      // console.log(e.response.data.error_code); // エラーコードを参照
    })
  },
  methods: {
  }
};
</script>